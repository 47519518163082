import axios from "axios";

export function getVideoApi() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const xhr = axios.request<null, any>({
    method: "get",
    url: "/introvideos",
  });
  return { xhr, cancel: (message: string) => source.cancel(message) };
}

interface ProcessedVideo {
  uri: string | Blob | File;
  type: string;
  name: string;
  isNative?: boolean;
}

export function uploadVideoApi(
  video: ProcessedVideo,
  onUploadProgress?: (progress: number) => void
) {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const formData = new FormData();
  if (video.isNative) {
    formData.append("introvideourl", {
      uri: video.uri,
      type: video.type,
      name: video.name,
    } as any);
  } else {
    formData.append("introvideourl", video.uri as Blob, video.name);
  }

  const xhr = axios.request<null, any>({
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    url: `/introvideos`,
    data: formData,
    onUploadProgress: (progressEvent) => {
      if (onUploadProgress) {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        onUploadProgress(percentage);
      }
    },
  });

  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteVideoApi() {
  return axios.request<null, any>({
    method: "delete",
    url: `/introvideos`,
  });
}
