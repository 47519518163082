import { combineReducers } from "redux";
import {
  CHANGE_PROFILE,
  DELETE_PROFILE,
  GET_ACTIVE_PROFILE,
  ProfileActionType,
  ProfileModel,
  SET_PROFILE,
} from "../../../models";

const initialState: ProfileModel[] = [];

export default combineReducers({
  data: (state: ProfileModel[] = initialState, action: ProfileActionType) => {
    switch (action.type) {
      case SET_PROFILE:
        return action.payload;
      case DELETE_PROFILE:
        return state.filter((portfolio) => portfolio.id !== action.payload);
      case GET_ACTIVE_PROFILE:
        return action.payload;
      case CHANGE_PROFILE:
        return state.filter((portfolio) => portfolio.id !== action.payload);
      default:
        return state;
    }
  },
});
