import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { deletePortfolioApi, getPortfolioApi } from "../../../api/portfolio";
import { DELETE_PORTFOLIO, SET_FAILED, SET_PORTFOLIO } from "../../../models";
import { AppState } from "../../reducers";

export const getAllPortfolioAction =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const response = await getPortfolioApi().xhr;
      if (response) {
        dispatch({ type: SET_PORTFOLIO, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILED, payload: { message: err.message } });
    }
  };

export const deletePortfolioAction =
  (id: number): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      await deletePortfolioApi(id);
      dispatch({ type: DELETE_PORTFOLIO, payload: id });
    } catch (err) {
      dispatch({ type: SET_FAILED, payload: { message: err.message } });
    }
  };
