import axios from "axios";

export function getPortfolioApi() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const xhr = axios.request<null, any>({
    method: "get",
    url: "/portfolios",
  });

  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deletePortfolioApi(id: number) {
  return axios.request<null, any>({
    method: "delete",
    url: `/portfolios/${id}`,
  });
}
