// Production Only
//  export const API_URL = "https://backend.sendmeatrainer.com/api/v1"

// SMAP Production
// export const API_URL = 'https://backend.sendmeapro.com/api/v1';

// Local Development Only
// export const API_URL = "http://192.168.0.143:4000/api/v1";
export const API_URL = 'https://backend-stag.sendmeatrainer.com/api/v1';

// Staging
//  export const API_URL = 'http://192.168.1.5:4000/api/v1';
