import { combineReducers } from "redux";
import account from "./app/account";
import loading from "./common/loading";
import geo from "./app/geo";
import alert from "./common/alert";
import franchise from "./app/franchise";
import franchiseDetail from "./app/franchiseDetails";
import category from "./app/categoryPcakage";
import packages from "./app/packages";
import coupons from "./app/coupons";
import requests from "./app/requests";
import sessions from "./app/sessions";
import setting from "./app/setting";
import goals from "./app/goals";
import injury from "./app/injury";
import place from "./app/place";
import frequency from "./app/frequency";
import certificates from "./app/certificate";
import specialties from "./app/specialties";
import allTrainers from "./app/allTrainers";
import chat from "./app/chat";
import buyPackage from "./app/buyPackage";
import fcm from "./app/fcm";
import dashboard from "./app/dashboard";
import reviews from "./app/reviews";
import packageTypes from "./app/packageTypes";
import modalPopup from "./app/modalPopup";
import appSettings from "./app/appSettings";
import taxRate from "./app/taxRate";
import payments from "./app/payments";
import languages from "./app/languages";
import categories from "./app/categories";
import sections from "./app/sections";
import formReducer from "./app/form";
import featuredTrainers from "./app/featuredTrainers";
import integration from "./app/integration";
import cleaningService from "./app/cleaningService";
import lawnService from "./app/lawnService";
import unlimitedSessionPackage from "./app/unlimitedSessionPackage";
import portfolioReducer from "./app/portfolio";
import profileReducer from "./app/profile";
import introVideoReducer from "./app/introVideo";
const rootReducer = combineReducers({
  account,
  loading,
  geo,
  alert,
  franchise,
  franchiseDetail,
  category,
  packages,
  sessions,
  coupons,
  requests,
  setting,
  goals,
  injury,
  place,
  frequency,
  certificates,
  specialties,
  allTrainers,
  chat,
  buyPackage,
  fcm,
  dashboard,
  reviews,
  packageTypes,
  modalPopup,
  appSettings,
  taxRate,
  payments,
  languages,
  categories,
  sections,
  featuredTrainers,
  formReducer,
  integration,
  cleaningService,
  lawnService,
  unlimitedSessionPackage,
  portfolioReducer,
  profileReducer,
  introVideoReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
