export interface PortfolioModel {
  id: number;
  image_url: string;
}

export const SET_PORTFOLIO = "SET_PORTFOLIO";
export const SET_FAILED = "SET_FAILED";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";

interface SetPortfolioAction {
  type: typeof SET_PORTFOLIO;
  payload: PortfolioModel[];
}

interface SetFailedAction {
  type: typeof SET_FAILED;
  payload: { message: string };
}

interface DeletePortfolioAction {
  type: typeof DELETE_PORTFOLIO;
  payload: number;
}

export type PortfolioActionType =
  | SetPortfolioAction
  | SetFailedAction
  | DeletePortfolioAction;
