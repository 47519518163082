export interface VideoModel {
  id: number;
  introvideourl: string;
}

export const SET_FAILED_VIDEO = "SET_FAILED_VIDEO";
export const SET_VIDEO = "SET_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";

interface SetVideoAction {
  type: typeof SET_VIDEO;
  payload: VideoModel[];
}
interface DeleteVideoAction {
  type: typeof DELETE_VIDEO;
}
export type VideoActionType = SetVideoAction | DeleteVideoAction;
