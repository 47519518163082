export interface ProfileModel {
  id: number;
  image_url: string;
  is_active: boolean;
}

export const SET_PROFILE = "SET_PROFILE";
export const SET_FAILED_PROFILE = "SET_FAILED_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const GET_ACTIVE_PROFILE = "GET_ACTIVE_PROFILE";
export const CHANGE_PROFILE = "CHANGE_PROFILE";

interface SetProfileAction {
  type: typeof SET_PROFILE;
  payload: ProfileModel[];
}

interface SetFailedAction {
  type: typeof SET_FAILED_PROFILE;
  payload: { message: string };
}

interface DeleteProfileAction {
  type: typeof DELETE_PROFILE;
  payload: number;
}

interface ChangeProfileAction {
  type: typeof CHANGE_PROFILE;
  payload: number;
}

interface GetActiveProfileAction {
  type: typeof GET_ACTIVE_PROFILE;
  payload: ProfileModel | null;
}

export type ProfileActionType =
  | SetProfileAction
  | SetFailedAction
  | DeleteProfileAction
  | GetActiveProfileAction
  | ChangeProfileAction;
