import {
  DELETE_PORTFOLIO,
  PortfolioActionType,
  PortfolioModel,
  SET_PORTFOLIO,
} from "../../../models";

import { combineReducers } from "redux";

const initialState: PortfolioModel[] = [];

export default combineReducers({
  data: (
    state: PortfolioModel[] = initialState,
    action: PortfolioActionType
  ) => {
    switch (action.type) {
      case SET_PORTFOLIO:
        return action.payload;
      case DELETE_PORTFOLIO:
        return state.filter((portfolio) => portfolio.id !== action.payload);
      default:
        return state;
    }
  },
});
