import { combineReducers } from "redux";
import {
  DELETE_VIDEO,
  SET_VIDEO,
  VideoActionType,
  VideoModel,
} from "../../../models/app/introVideo";
const initialState: VideoModel[] = [];
export default combineReducers({
  data: (state: VideoModel[] = initialState, action: VideoActionType) => {
    switch (action.type) {
      case SET_VIDEO:
        return action.payload;
      case DELETE_VIDEO:
        return state;
      default:
        return state;
    }
  },
});
