import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

import { AppState } from "../../reducers";
import {
  changeProfileApi,
  deleteProfileApi,
  getActiveProfileApi,
  getProfileApi,
} from "../../../api/profile";
import {
  CHANGE_PROFILE,
  DELETE_PROFILE,
  GET_ACTIVE_PROFILE,
  SET_FAILED_PROFILE,
  SET_PROFILE,
} from "../../../models";

export const getAllProfileAction =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const response = await getProfileApi().xhr;
      console.log("Profile response", response);
      if (response) {
        dispatch({ type: SET_PROFILE, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILED_PROFILE, payload: { message: err.message } });
    }
  };

export const deleteProfileAction =
  (id: number): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      await deleteProfileApi(id);
      dispatch({ type: DELETE_PROFILE, payload: id });
    } catch (err) {
      dispatch({ type: SET_FAILED_PROFILE, payload: { message: err.message } });
    }
  };

export const getActiveProfileAction =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const response = await getActiveProfileApi().xhr;
      if (response) {
        dispatch({ type: GET_ACTIVE_PROFILE, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILED_PROFILE, payload: { message: err.message } });
    }
  };

export const changeProfileAction =
  (id: number): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      await changeProfileApi(id);
      dispatch({ type: CHANGE_PROFILE, payload: id });
    } catch (err) {
      dispatch({ type: SET_FAILED_PROFILE, payload: { message: err.message } });
    }
  };
