import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { AppState } from "../../reducers";
import {
  deleteVideoApi,
  getVideoApi,
  uploadVideoApi,
} from "../../../api/introVideo";
import { startLoading, stopLoading } from "../common/loading";
import { START_LOADING, STOP_LOADING } from "../../../models";
import {
  DELETE_VIDEO,
  SET_FAILED_VIDEO,
  SET_VIDEO,
} from "../../../models/app/introVideo";

export const getAllVideoAction =
  (): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      const response = await getVideoApi().xhr;
      if (response) {
        dispatch({ type: SET_VIDEO, payload: response });
      }
    } catch (err) {
      dispatch({ type: SET_FAILED_VIDEO, payload: { message: err.message } });
    }
  };

interface ProcessedVideo {
  uri: string | Blob | File;
  type: string;
  name: string;
  isNative?: boolean;
}

export const uploadVideoAction =
  (
    video: ProcessedVideo,
    onUploadProgress?: (progress: number) => void,
    enableGlobalLoader: boolean = true
  ): ThunkAction<Promise<any>, AppState, null, Action<string>> =>
  async (dispatch, getState) => {
    try {
      if (enableGlobalLoader) {
        startLoading(START_LOADING)(dispatch, getState, null);
      }

      const response = await uploadVideoApi(video, onUploadProgress).xhr;
      if (response) {
        return response;
      }

      dispatch({
        type: SET_FAILED_VIDEO,
        payload: { message: "Failed to upload video." },
      });
      return false;
    } catch (err) {
      dispatch({
        type: SET_FAILED_VIDEO,
        payload: { message: err.message || "Failed to upload video." },
      });
      return false;
    } finally {
      if (enableGlobalLoader) {
        stopLoading(STOP_LOADING)(dispatch, getState, null);
      }
    }
  };

export const deleteVideoAction =
  (id: number): ThunkAction<void, AppState, null, Action<string>> =>
  async (dispatch) => {
    try {
      await deleteVideoApi();
      dispatch({ type: DELETE_VIDEO });
    } catch (err) {
      dispatch({ type: SET_FAILED_VIDEO, payload: { message: err.message } });
    }
  };
