import axios from "axios";

export function getProfileApi() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const xhr = axios.request<null, any>({
    method: "get",
    url: "/profile",
  });

  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function deleteProfileApi(id: number) {
  return axios.request<null, any>({
    method: "delete",
    url: `/profile/${id}`,
  });
}

export function getActiveProfileApi() {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const xhr = axios.request<null, any>({
    method: "get",
    url: "/profile/active-profile",
  });

  return { xhr, cancel: (message: string) => source.cancel(message) };
}

export function changeProfileApi(id: number) {
  return axios.request<null, any>({
    method: "put",
    url: `/profile/active-profile/${id}`,
  });
}
